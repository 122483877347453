import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.home--banner-slider').slick({
		arrows: true,
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: ".slick-prev-custom",
		nextArrow: ".slick-next-custom"
	});	

	$('.slick--home--publications').slick({
		arrows: true,
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 1,
		prevArrow: ".slick-prev-publis",
		nextArrow: ".slick-next-publis",
		responsive: [
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});

	$('.slider--produits').slick({
		arrows: true,
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 1,
		prevArrow: ".slick-prev-produits",
		nextArrow: ".slick-next-produits",
		responsive: [
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	});

});